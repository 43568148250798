import {Badge} from '~/components/ui/Badge';
import ContentCard from '~/components/ContentCard';

export type PromotionCardProps = {
  id: string
  link?: string
  imageSrc: string
  endingSoon?: boolean
  vipOnly?: boolean
  title: string
  categories?: string[]
}

const PromotionCard = (props: PromotionCardProps) => {
  const {id, link, imageSrc, endingSoon, vipOnly, title, categories} = props;

  return <ContentCard
    link={link ?? `/promotions/${id}`}
    imageSrc={imageSrc}
    imageTags={[
      endingSoon && <Badge variant="primary">Ending Soon</Badge>,
      vipOnly && <Badge variant="vip">VIP Only</Badge>
    ]}
    title={title}
    subTitle={categories?.map(category => (
      <Badge variant="secondary" key={category}>
        {category}
      </Badge>
    ))}
  />;
}

export default PromotionCard;
